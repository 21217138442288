/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveBreaks, resolveMeta } from "@dataResolvers";
import { Home as PageTemplate } from "@pageTemplates";

export const query = graphql`
query {
  craft {
    # Get first two posts
    entries(section: "post", limit: 2) {
      ... on Craft_post_post_Entry {
        title
        descriptor0
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        url
        categories(limit: 1) {
          slug
        }
      }
    }
    entry(section: "home") {
      ... on Craft_home_home_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Hero
        heroHeading
        heroDescription
        heroButtonLink {
          url
          text
        }
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Select Projects
        selectProjectsHeadline
        selectProjects {
          ...on Craft_selectProjects_selectProject_BlockType {
            projectName
            projectImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            projectLink {
              url
              text
            }
          }
        }
        selectProjectsLink {
          url
          text
        }
        heading0
        descriptor0
        descriptor2
        link0 {
          text
          url
        }
        quotes {
          ...on Craft_quotes_quote_BlockType {
            quote
            quoteAuthor
            authorImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            companyImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        boolean0
        insightsHeading
        insightsSubheading
        linkField {
          url
          text
        }
      }
    }
  }
}
`;

const previewQuery = `query {
  craft {
    # Get first two posts
    entries(section: "post", limit: 2) {
      ... on Craft_post_post_Entry {
        title
        descriptor0
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        url
        categories(limit: 1) {
          slug
        }
      }
    }
    entry(section: "home") {
      ... on Craft_home_home_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Hero
        heroHeading
        heroDescription
        heroButtonLink {
          url
          text
        }
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Select Projects
        selectProjectsHeadline
        selectProjects {
          ...on Craft_selectProjects_selectProject_BlockType {
            projectName
            projectImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            projectLink {
              url
              text
            }
          }
        }
        selectProjectsLink {
          url
          text
        }
        heading0
        descriptor0
        descriptor2
        link0 {
          text
          url
        }
        quotes {
          ...on Craft_quotes_quote_BlockType {
            quote
            quoteAuthor
            authorImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            companyImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        boolean0
        insightsHeading
        insightsSubheading
        linkField {
          url
          text
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    uid,
    url,
    slug,
    metaTitle,
    metaDescription,
    metaImage,
    heroHeading,
    heroDescription,
    heroButtonLink,
    heroBackgroundImage,
    selectProjectsHeadline,
    selectProjects,
    selectProjectsLink,
    insightsHeading,
    insightsSubheading,
    linkField: insightsLink,
    quotes,
    heading0,
    descriptor0,
    descriptor2,
    link0,
    boolean0,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: resolveBreaks(heroHeading),
      description: heroDescription,
      image: resolveImage(heroBackgroundImage),
      button: heroButtonLink,
    },
    selectProjects: {
      headline: resolveBreaks(selectProjectsHeadline),
      projects: selectProjects.map( project => {
          return {
            project: project.projectName,
            image: resolveImage(project.projectImage),
            link: project.projectLink,
          }
      }),
      link: selectProjectsLink,
    },
    manifesto: {
      heading: heading0,
      subhead: descriptor0,
      description: descriptor2,
      button: link0,
    },
    testimonials: {
      quotes: quotes.map( quote => {
        return {
          quote: quote.quote,
          author: quote.quoteAuthor,
          image: resolveImage(quote.authorImage),
          companyImage: resolveImage(quote.companyImage),
        }
      }),
    },
    insights: {
      hide: boolean0,
      heading: resolveBreaks(insightsHeading), 
      subhead: insightsSubheading,
      link: insightsLink,
      posts: craft.entries.map( post => {
        return {
          title: post.title,
          description: post.descriptor0,
          category: post.categories[0].slug.toString(),
          link: post.url,
          image: resolveImage(post.heroImage),
        };
      }),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
